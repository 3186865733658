.HomePage-Layout-Container {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 120px auto 150px;
}

.HomePage-HeaderBar-Container {
  grid-row-start: 1;
  grid-row-end: 2;
  border-bottom: 1.5px solid grey;
}

.Content-Container {
  grid-row-start: 2;
  grid-row-end: 3;
  margin-bottom: 70px;
}

.FooterBar-Container {
  grid-row-start: 3;
  grid-row-end: 4;
}