.ProjectPage-HeaderBar-Container {
  display: grid;
  /* grid-template-rows: 10% 45% 45%; */
  /* grid-template-rows: 25% 45% 30%; */
  grid-template-rows: 50% 50%;
  text-align: left;
  border-bottom: 1.5px solid grey;
}

.Header-Topic-Container {
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.Home-Link-Container a{
  color: #767676;
  text-decoration: none;
}

.Home-Link-Container a:focus{
  color: #767676;
  text-decoration: none;
}

.Header-Topic {
  color: #00C7D8;
}

.Header-Content-Container {
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 5px;
}