.SideBar-Container {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.SideaBar-Menu {
  padding: 10px 0;
}

.SideaBar-Menu a {
  color: #888787;
  text-decoration: none;
}

.SideaBar-Menu a:focus {
  color: #00c7d8;
  text-decoration: none;
}