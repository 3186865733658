.Project-Container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5%;
  justify-content: space-between;
}

.Project-Item-Container {
  width: 100%;
  margin-top: 50px;
  text-align: left;
}

.End-Line-Project-Item {
  border-top: 1.5px dashed grey;
}

.Project-Title-Container {
  height: 65px;
  margin-bottom: 5px;
}

.Project-Item-Container a {
  color: #000000;
  text-decoration: none;
}

.Project-Picture {
  margin-bottom: 20px;
}
.Project-Description {
  height: 85px;
  color: #888787;
}

@media only screen and (max-width: 800px) {
  .Project-Container {
    grid-template-columns: 1fr;
  }

  .Project-Item-Container {
    margin-top: 25px;
  }

  .Project-Title-Container {
    height: 50px;
  }

  .Project-Picture {
    margin-bottom: 10px;
  }
}