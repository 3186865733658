#Overview {
  margin-top: 50px;
  padding-bottom: 30px;
  border-bottom: 1.5px dashed #00C7D8;
}

#Situation-Assessment {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1.5px dashed #00C7D8;
}

#Research {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1.5px dashed #00C7D8;
}

#Strategy {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1.5px dashed #4E4E4E;
}

#End1 {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1.5px dashed #4E4E4E;
}

#End2 {
  padding-top: 30px;
  padding-bottom: 30px;
}
