.FooterBar-Container {
  border-top: 1.5px solid grey;
  display: grid;
  grid-template-rows: 70% 30%;
  text-align: left;
}

.FooterBar-Content-Container {
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: space-around;
}

.FooterBar-Contact {
  color: #00C7D8;
  padding-top: 25px;
}