@font-face {
  font-family: 'Helveticalt';
  src: local('Helveticalt'), url(./fonts/Helveticalt/Linotype-HelveticaLTPro-Roman.otf) format('opentype');
}

@font-face {
  font-family: 'HelveticaltPro-Bold';
  src: local('Helveticalt'), url(./fonts/Helveticalt/Linotype-HelveticaLTPro-Bold.otf) format('opentype');
}

.App {
  text-align: center;
  max-width: 1500px;
  padding: 50px;
  margin: 0 auto;
  font-family: 'Helveticalt', 'HelveticaltPro-Bold';
  background: white;
  line-height: 25px;
}

#Topic {
  font-family: 'HelveticaltPro-Bold';
  font-size: 22px;
}

#Topic-Description {
  font-family: 'HelveticaltPro-Bold';
  font-size: 16px;
}

#Ugly-Topic-Description {
  font-family: 'HelveticaltPro-Bold';
  font-size: 16px;
}

#Side-Sub-Topic {
  font-family: 'HelveticaltPro-Bold';
  font-size: 19px;
}

#Sub-Topic {
  font-family: 'HelveticaltPro-Bold';
  font-size: 16px;
}

#Description {
  font-size: 16px;
}

#In-Detail {
  font-size: 12px;
}

#Topic-In-Detail {
  font-family: 'HelveticaltPro-Bold';
  font-size: 12px;
}

@media only screen and (max-width: 800px) {
  .App {
    padding: 20px;
    line-height: 20px;
  }

  #Topic {
    font-family: 'HelveticaltPro-Bold';
    font-size: 18px;
  }
  
  #Topic-Description {
    font-family: 'HelveticaltPro-Bold';
    font-size: 14px;
  }

  #Ugly-Topic-Description {
    font-family: 'HelveticaltPro-Bold';
    font-size: 12px;
    line-height: 18px;
  }
  
  #Side-Sub-Topic {
    font-family: 'HelveticaltPro-Bold';
    font-size: 15px;
  }
  
  #Sub-Topic {
    font-family: 'HelveticaltPro-Bold';
    font-size: 14px;
    /* 12 */
  }
  
  #Description {
    font-size: 12px;
  }
  
  #In-Detail {
    font-size: 8px;
  }
  
  #Topic-In-Detail {
    font-family: 'HelveticaltPro-Bold';
    font-size: 8px;
  }

  #link-back {
    font-size: 12px;
  }
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 1px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  /* width: 1px;
  background-color: #f5f5f5; */
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  /* background-color: #b5b5b5; */
}