.Project-Sector-Name-Container {
  margin-bottom: 30px;
  color: #00C7D8;
}

#Project-Custom-Picture {
  display: flex;
}

#Project-Image-Sources {
  color: #767676;
}

#Overview {
  margin-top: 50px;
  padding-bottom: 30px;
  border-bottom: 1.5px dashed #00C7D8;
}

#Research {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1.5px dashed #00C7D8;
}

#Design-project2 {
  padding-top: 30px;
  padding-bottom: 30px;
}
