#Overview {
  margin-top: 50px;
  padding-bottom: 30px;
  border-bottom: 1.5px dashed #00C7D8;
}

#Problem-Found {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1.5px dashed #00C7D8;
}

#Research {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1.5px dashed #00C7D8;
}

#Design-project5 {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1.5px dashed #00C7D8;
}

#Prototype {
  padding-top: 30px;
  padding-bottom: 30px;
}
