.ProjectPage-Layout-Container {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 120px auto;
}

.ProjectPage-HeaderBar-Container{
  grid-column-start: 1;
  grid-column-end: 2;
  border-bottom: 1.5px solid grey;
  position: sticky;
  top: 50px;
  background: white;
}

.Project-Content-Container {
  grid-row-start: 2;
  grid-row-end: 3;
  display: grid;
  grid-template-columns: 15% 1% 84%;
  grid-template-rows: auto 150px;
  overflow: auto;
}

/* set to non display */
.SideBar-Container {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  position: fixed;
}

/* set to 100 */
.Project-Information-Container {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 3;
  grid-column-end: 4;
  padding-top: 20px;
  margin-bottom: 50px;
  text-align: left;
}

/* .Project-Information-Container {
  text-align: left;
} */

.Project-Single-Content-Container {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.Project-Many-Content-Container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;
}

.Many-Item-Container {
  width: 50%;
}

.Project-Topic-Container {
  margin-top: 15px;
  margin-bottom: 15px;
}

#Project-Topic-And-Detail {
  margin-bottom: 40px;
}

.Project-Footer-Container {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 4;
}

@media only screen and (max-width: 800px) {
  .SideBar-Container {
    display: none !important;
  }

  .Project-Information-Container {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 4;
  }
}
