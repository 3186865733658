.Everything-Item-Container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 3%;
  justify-content: space-between;
  margin-top: 100px;
}

.Ugly-Everything-Item-Container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 3%;
  justify-content: space-between;
  margin-top: 20px;
}

#everything-picture-frame {
  align-self: flex-end;
  align-items: flex-end;
}

.Everything-Item-Name {
  text-align: left;
  align-items: flex-start;
}

.Archive-Container {
  display: flex;
  flex-direction: column;
}

.Archirve-Description-Container {
  text-align: left;
  margin-top: 50px;
}

@media only screen and (max-width: 800px) {
  .Everything-Item-Container {
    margin-top: 40px;
  }

  .Archirve-Description-Container {
    text-align: left;
    margin-top: 25px;
  }
}
