.HomePage-HeaderBar-Container {
  display: grid;
  grid-template-rows: 10% 45% 45%;
  text-align: left;
}

.Header-Description-Container {
  grid-row-start: 2;
  grid-row-end: 3;
  padding-top: 15px;
  border-bottom: 1.5px solid grey;
}

.Header-Description {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

#Header-Description {
  padding-top: 15px;
}

.Header-Menu-Container {
  grid-row-start: 3;
  grid-row-end: 4;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Project-Link-Container {
  margin-right: 60px;
}

@media only screen and (max-width: 800px) {
  .HomePage-HeaderBar-Container {
    display: grid;
    grid-template-rows: 7% 45% 47%;
    text-align: left;
  }
}

@media only screen and (max-width: 350px) {
  .HomePage-HeaderBar-Container {
    display: grid;
    grid-template-rows: 4% 61% 35%;
    text-align: left;
  }
}