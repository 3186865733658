.Carousel-Container {
  display: flex;
  flex-direction: column;
}

#Carousel-Action-Container {
  display: flex;
  flex-direction: row;
  background: white;
}

.Carousel-Button {
  flex-grow: 1;
}

#Carousel-Board-Container {
  background: white;
  border: 0.5px solid #E3E3E3;
  border-top: 0px;
  padding: 10px;
  padding-top: 20px;
}